nav {
  background-color: rgb(0, 0, 0);
  /* background-color: rgb(255, 255, 255); */
  position: fixed;
  width: 100%;
  transition: all 1.5s;
  z-index: 5;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 2.5rem;
  font-weight: 600;
}

.navbar {
  box-shadow: 0 2px 6px #00000083;
}
.navitems {
  color: white;
  text-decoration: none;
  padding: 1rem;
  font-size: 2rem;
  transition: all 1s;
}

.navitems:hover,
.nav-items-active:hover {
  color: #8ed4ff;
}

.active-page {
  box-shadow: inset 0 0 0 3px #fff;
}

.nav-items-active {
  color: #fff;
  text-decoration: none;
  padding: 1rem;
  font-size: 2rem;
  transition: all 1s;
  box-shadow: inset 0 0 0 3px #fff;
}

.sticky {
  background-color: black;
}
