/*************************/
/* Landing page */
/*************************/

.container {
  background-image: linear-gradient(
      rgba(34, 34, 34, 0.6),
      rgba(34, 34, 34, 0.7)
    ),
    url(../imgs/emile-perron-xrVDYZRGdw4-unsplash.jpg);
  background-size: cover;
  height: 100vh;
  position: relative;
  margin: 0 auto;
}

.hero {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  /* background-color: rgb(230, 255, 255); */
  background-color: none;
  color: #fff;
  max-width: 70%;
  padding: 5rem;
  border-radius: 3rem;
}

ul {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.list-item {
  list-style: none;
  padding: 1rem;
}

.name-section {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-size: 6rem;
  margin-bottom: 2rem;
  font-weight: 700;
}

.btn:link,
.btn:visited {
  border: none;
  background: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  padding: 1rem;
  transition: all 2s;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
}

.btn:hover {
  color: #999595;
  /* box-shadow: inset 0 0 0 3px black; */
  /* font-size: 2.2rem; */
}
